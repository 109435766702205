var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: _vm.tool_count,
      staticClass: "trading-vue-toolbar",
      style: _vm.styles,
    },
    [
      _vm._l(_vm.groups, function (tool, i) {
        return tool.icon && !tool.hidden
          ? _c("toolbar-item", {
              key: i,
              attrs: {
                data: tool,
                subs: _vm.sub_map,
                dc: _vm.data,
                config: _vm.config,
                colors: _vm.colors,
                selected: _vm.is_selected(tool),
              },
              on: { "item-selected": _vm.selected },
            })
          : _vm._e()
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.handleButtonClick("f")
            },
          },
        },
        [_vm._v("FS")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.handleButtonClick("z")
            },
          },
        },
        [_vm._v("MA")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.handleButtonClick("x")
            },
          },
        },
        [_vm._v("MK")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.handleButtonClick("s")
            },
          },
        },
        [_vm._v("RS")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.handleButtonClick(">")
            },
          },
        },
        [_vm._v("-->")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.handleButtonClick("<")
            },
          },
        },
        [_vm._v("<--")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.handleButtonClick("p")
            },
          },
        },
        [_vm._v("SW")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }