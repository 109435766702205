var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass: "tvjs-the-tip",
    style: _vm.style,
    domProps: { innerHTML: _vm._s(_vm.data.text) },
    on: {
      mousedown: function ($event) {
        return _vm.$emit("remove-me")
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }