var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("img", {
    staticClass: "t-vue-lbtn",
    style: {
      width: _vm.config.L_BTN_SIZE + "px",
      height: _vm.config.L_BTN_SIZE + "px",
      margin: _vm.config.L_BTN_MARGIN,
    },
    attrs: { src: _vm.base64, id: _vm.uuid },
    on: { click: _vm.onclick },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }